<script>
import { VclCode } from 'vue-content-loading';
import { mapState } from "vuex";

import TimeAgo from 'vue2-timeago';
import {OrbitSpinner} from "epic-spinners";

import {get_acsrf_token} from "@/methods";

export default {
  components: {
    VclCode,
    //OrbitSpinner
  },
  props: {

  },
  computed: {

  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getData: function() {
      fetch(process.env.VUE_APP_ROOT_API + 'v1/sevDesk/widgets/profitloss', {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.data = data;

          this.chartData.series = [data.profit, data.costs];

          this.ready = true;

        })
        .catch(error => {
          this.handleError(error);
        });
    },
  },
  created() {
    this.getData();
  },
  destroyed() {

  },
  data() {
    return {
      ready: false,
      error: false,
      data: {},
      chartData: {
        series: [0, 0],
        chartOptions: {
          chart: {
            type: 'donut',
            height: 240,
          },
          labels: ['Profit', 'Expenses'],
          colors: ['#34c38f', '#f46a6a'],
          legend: {
            show: false,
          },
          plotOptions: {
            pie: {
              donut: {
                size: '70%',
              }
            }
          }
        }
      }
    }
  }
};
</script>

<template>
  <div v-if="ready">
    <apexchart
        ref="profitLoss"
        class="apex-charts"
        dir="ltr"
        width="300"
        :series="chartData.series"
        :options="chartData.chartOptions"
    ></apexchart>
    <div class="text-center text-muted">
      <div class="row">
        <div class="col-4">
          <div class="mt-4">
            <p class="mb-2 h6 text-truncate text-uppercase">
              <i class="mdi mdi-circle text-primary me-1"></i>
              Total Revenue
            </p>
            <h5>{{ $n(data.earnings, {locale: 'de'}) }} €</h5>
          </div>
        </div>
        <div class="col-4">
          <div class="mt-4">
            <p class="mb-2  h6 text-truncate text-uppercase">
              <i class="mdi mdi-circle text-success me-1"></i>
              Profit
            </p>
            <h5>{{ $n(data.profit, {locale: 'de'}) }} €</h5>
          </div>
        </div>
        <div class="col-4">
          <div class="mt-4">
            <p class="mb-2  h6 text-truncate text-uppercase">
              <i class="mdi mdi-circle text-danger me-1"></i>
              Expenses
            </p>
            <h5>{{ $n(data.costs, {locale: 'de'}) }} €</h5>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col justify-content-center text-center">
          <div class="mt-4 h5">
            <p class="mb-2 text-truncate text-uppercase">
              Net-Profit
            </p>
            <h3 style="color: #f711e4;">{{ $n(data.netprofit, {locale: 'de'}) }} €</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="error">
    <div class="row">
      <div class="col-lg-12 justify-content-start">
        <div class="card border border-danger">
          <div class="card-header bg-transparent border-danger">
            <h5 class="my-0 text-danger">
              <i class="far fa-exclamation-circle mr-3"></i> {{ $t('error.server.generic.title') }}
            </h5>
          </div>
          <div class="card-body">
            <h5 class="card-title mt-0">
              {{ $t('dashboard.error.info') }}
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-lg-12 justify-content-start">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <vcl-code :height="90"></vcl-code>
              <div class="ml-4 mt-4 h4 text-truncate text-uppercase">
                Calculating profit & loss...
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
